/////////////////////////
// pwa attendees       //
/////////////////////////
export const PWA_Save_Class_Attendees = ( CurrentAttendees ) => {
  var obj = { "count": CurrentAttendees.length, "attendees": CurrentAttendees};
  localStorage.setItem('PWA_Current_Attendees', JSON.stringify( obj ) );
}

export const PWA_Reset_Class_Attendees = () => {
  var obj = { "count": 0, "attendees": []};
  localStorage.setItem('PWA_Current_Attendees', JSON.stringify( obj ) );
}

export const PWA_Load_Class_Attendees = () => {
  if ( localStorage.getItem('PWA_Current_Attendees')) {
       return ( JSON.parse( localStorage.getItem('PWA_Current_Attendees')) );
  } 
  return( { "count": 0, "attendees": []} );  
}