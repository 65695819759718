  /////////////////////////////////////////
  // get the current cache                //
  //////////////////////////////////////////
  export const PWA_Cache_Get = () => {

    let PWA_Cache = { "checkins" : [] };
    if (localStorage.getItem('PWA_Cache')) {
        PWA_Cache = JSON.parse( localStorage.getItem('PWA_Cache') );
    }
    return PWA_Cache;
  }

  ////////////////////////////////////////////
  // get the current Token                 //
  ///////////////////////////////////////////
  export const PWA_Cache_Get_Token = () => {

    let PWA_Token = "";
    if (localStorage.getItem('PWA_Token')) {
        PWA_Token = localStorage.getItem('PWA_Token');
    }
    return PWA_Token;
  }

  ////////////////////////////////////////////
  // set the current Cache                 //
  ///////////////////////////////////////////
  export const PWA_Cache_Set = (PWA_Cache) => {

      const LatestCache = JSON.stringify( PWA_Cache );
      localStorage.setItem('PWA_Cache', LatestCache);
  }

  //////////////////////////////////////////////
  // append to Cache                          //
  //////////////////////////////////////////////
  export const PWA_Cache_Append = ( CheckinObject )  => {

    // get current cache
    let PWA_Cache = PWA_Cache_Get();
  
    // append new simplified Checkin Object to cache
    const simpleObj = { 
                         // v1.30 add the location id also
                         "locationId"   : CheckinObject.locationId,
                         "locationName" : CheckinObject.locationName,
                         "instructor"   : CheckinObject.instructor,
                         "student"      : CheckinObject.student,
                         "timestamp"    : CheckinObject.timestamp
                     };

    PWA_Cache.checkins.push( simpleObj );
   
    // update the cache
    PWA_Cache_Set(PWA_Cache);
  }

  //////////////////////////////////////////
  // reset the  cache                     //
  //////////////////////////////////////////
  export const PWA_Cache_Reset = ()  => {
    localStorage.removeItem('PWA_Cache');
  }

  //////////////////////////////////////////
  // get  cache size                      //
  //////////////////////////////////////////
  export const PWA_Cache_Get_Size = () => {

    const data = localStorage.getItem('PWA_Cache')
    if ( data )  {
          return data.length;
    } else
         return 0;
  }

  export const PWA_Cache_Get_Count = () => {

    const data = localStorage.getItem('PWA_Cache')
    if ( data )  {
          var temp = JSON.parse(data);
          return temp.checkins.length;
    } else
         return 0;
  }