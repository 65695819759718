import React from 'react';
import { makeStyles }              from '@material-ui/core/styles';
import BottomNavigation            from '@material-ui/core/BottomNavigation';
import BottomNavigationAction      from '@material-ui/core/BottomNavigationAction';
import { FontAwesomeIcon }         from '@fortawesome/react-fontawesome'
import { faHome}                   from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles({
  root: {
    width: 100,
  },
});

function GoHomeNav( actions ) {
  const classes           = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
       value={value}
       
       onChange={ (event, newValue) => { 
            setValue(newValue);
            switch(newValue) {
              case 0:
                  actions.actions.PWA_Set_View("INSTRUCTOR_HOME_VIEW");
                  break;
              default:
            }
          }
       }
       
       showLabels
       className={classes.root}
    >
     <BottomNavigationAction label="Home"        icon={<FontAwesomeIcon icon={faHome}/>} />
    </BottomNavigation>
  );
}

export default GoHomeNav