import React                        from 'react';
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';

import { FontAwesomeIcon }    from '@fortawesome/react-fontawesome'
import { faSignInAlt }        from "@fortawesome/free-solid-svg-icons";


import * as AppActions              from '../store/actions/AppActions';
import { Container, Button, Form }  from 'react-bootstrap';

import * as Credentials from "../store/credentials";


function mapDispatchToProps(dispatch) {
  const actions = Object.assign( {}, AppActions );
  return { actions: bindActionCreators( actions, dispatch)  };
}

function mapStateToProps(store) {
  return {  AppReducer  : store.AppReducer };
}

class LoginForm extends React.Component {

  componentDidMount() {

    var _credentials = Credentials.PWA_Load_Credentials();
    if ( _credentials )
       this.setState( { email: _credentials.email, password: _credentials.password } )
  }

  constructor(props) {
    super(props);
      this.state = {
        'email'    : '',
        'password' : '',
    }
    this.handleChange = this.handleChange.bind(this);
  }
  

  validateForm() {
    return (  this.state.email.length > 0 &&
              this.state.password.length > 0  &&
              this.validateEmail() );
  }
  
  validateEmail = () => {
  
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test( this.state.email) ) {
           return true;
        } else {

          return false;
        }
  }

  handleChange = event => {
      this.setState({ [event.target.id]: event.target.value });
  }
   
  handleSubmit = async event => {
        event.preventDefault();
        const credentials = {  "email"    : this.state.email,
                               "password" : this.state.password }

        this.props.actions.PWA_Login(credentials);

  }

  
    render() {
      return (
        <Container className="App">
       
       <Form onSubmit={this.handleSubmit}>
        
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus = {true}
            type    = "email"
            value   = {this.state.email}
            onChange= {this.handleChange}
          />
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            value     = {this.state.password}
            onChange  = {this.handleChange}
            type      = "password"
          />
        </Form.Group>

        <Button className="mt-2 w-100" disabled={!this.validateForm()} type="submit">
           <span>
              
              <FontAwesomeIcon icon={faSignInAlt}/>
              &nbsp;&nbsp;Login
           </span>
        </Button>

      </Form>
      

        </Container>
      );
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(LoginForm );