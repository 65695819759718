/////////////////////////
// pwa credentials     //
/////////////////////////
export const PWA_Save_Credentials = ( Credentials ) => {
  localStorage.setItem('PWA_Credentials', JSON.stringify( Credentials) );
}

export const PWA_Reset_Credentials = () => {
  localStorage.removeItem('PWA_Credentials');
}

export const PWA_Load_Credentials = () => {

  if ( localStorage.getItem('PWA_Credentials')) {
       return ( JSON.parse( localStorage.getItem('PWA_Credentials')) );
  } 
  return null;  
}