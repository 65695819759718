function gettimestamp() {
     var today = new Date();
     var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
     var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
     var dateTime = date +'  ' + time;
     return dateTime;
};

function logevent( message) {
     var dateTime = gettimestamp(); 
     var event    = `${dateTime}- ${message}`;
     var log      = localStorage.getItem( "PWA_log" );
     if( log === null) { log  = [] }
     else { log = JSON.parse( log ) };   
  
     log.push( event );
     localStorage.setItem("PWA_log", JSON.stringify( log ) );
} 

function loadlog() {
    var log = localStorage.getItem("PWA_log" );
    if( log === null) log = "";
    return log;
}
  
function resetlog() {
     localStorage.removeItem("PWA_log");
}

export {logevent, loadlog, resetlog };