import React from 'react';
import { makeStyles }       from '@material-ui/core/styles';
import Paper                from '@material-ui/core/Paper';
import InputBase            from '@material-ui/core/InputBase';
import Divider              from '@material-ui/core/Divider';
import IconButton           from '@material-ui/core/IconButton';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome'
import { faSearch}          from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});




function SearchInput(actions) {

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <InputBase  onChange={ (event) => {  
                       if ( event.target.value.length > 2) { 
                           actions.actions.PWA_Search_Students( event.target.value )
                        }
                  }
      }
      autoFocus={true}
      className={classes.input}
      placeholder="Enter student name (first 3 chars)"
      />
      <Divider className={classes.divider} />
      <IconButton className={classes.iconButton} aria-label="Search">
        <FontAwesomeIcon icon={faSearch}/>
      </IconButton>
    </Paper>
  );
}

export default SearchInput;
