import * as Logger      from "./logger";
import axios from        'axios';

async function get_token( credentials ) {

    const email              =  credentials.email;
    const password           =  credentials.password;
    let loginURL             =  `https://members.utaonline.co.uk/api/login?email=${email}&password=${password}`;
    const encodedLoginURL    =  encodeURI( loginURL );

    Logger.logevent(`get_token called using ${loginURL}`);
 
    let myPromise = new Promise((resolve, reject) => {
    
         var result = {};
         axios.post(encodedLoginURL)
          .then(function (response) {
              if ( response.status === 200) {
                   result.status = response.status;
                   result.token  = response.data.token;
                   resolve( result );
              } else { 
                   result.status = response.status;
                   result.token  = "";
                   result.message = "network error";
                   reject( result );
              }     
           })
          .catch(function (error) {
              result.status = 400;
              result.token  = "";
              result.message = error.messsage;
              reject( result );
       });
    });

    return ( myPromise );
}

async function upload_cache( tokenResult , cacheResult ) {
 
    const  upLoadURL    = "https://members.utaonline.co.uk/api/checkins"
    const  config       = {
            headers: {
              "Accept"        : "application/json",
              "Content-Type"  : "application/json",
              "Authorization" : `Bearer ${tokenResult.token}`
            }
    };

    let myPromise = new Promise((resolve, reject) => {

        axios.post(upLoadURL, cacheResult.cache, config)
        .then( function( response ){
             resolve( response );
        })
        .catch( function( error ) {
             reject( error );
        });
    });

   return ( myPromise );

}

export {get_token, upload_cache };