// added in 1.43
// 5-30-2024 1.44 Added the test for Null.

import moment from 'moment';

export function ageInYears( dateOfBirth ) {

        let  years = "N/A";
           if ( dateOfBirth !== null ) {
            var today    = moment( new Date() );
            var dob      = moment( new Date(dateOfBirth) );
            var duration = moment.duration( today.diff(dob) )      
            years        = duration.years();
        }

         return years;
}
     
export function lastGradingDate( lastGradingDate ) {
        
        let  formatted = "N/A";
        if ( lastGradingDate !== null ) {
             formatted = moment(lastGradingDate).format("dddd, MMMM Do YYYY"); 
        }
 
        return formatted;


}
    
export function presentGrade( presentGrade ){
   
        let  formatted = "N/A";
   
        if ( presentGrade !== null ) {
                formatted = presentGrade;
        }
        return ( formatted);
};