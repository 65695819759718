import * as Cache     from "../cache";
import * as Attendees from "../attendees";

const initialState = {
      token                 : "",
    
      students              : {},
      clubStudents          : [],
      myStudents            : [],

      showLoginSpinner      : false,
      showAlert             : false,
      showAlertMessage      : "",

      currentView           : "LOGIN_VIEW",
      currentInstructor     : {},
      currentStudent        : {},

      LastCheckIn           : {},
      checkedIn             : [],
      searchResult          : [],

      pending_checkins_count       : 0,
      show_cache_upload_spinner    : false,

      //////////////////////////
      // the location Object  //
      //////////////////////////
      Location             :  { Required               : false,
                                Current                : {id:0, name:"Location"},   //the current location for an instructor
                                Locations              : [],                        //array of availible locations to select from
                              }
  }
    
  export const AppReducer = (state=initialState, action) => {

    switch(action.type) {

      case  'PWA_APPEND_EXTERNAL_STUDENT' : {
         var newArray = state.clubStudents.slice();    
         newArray.push(  action.payload );   
         return { ...state, clubStudents: newArray}
      }
      
      case 'PWA_UPLOAD_CACHE_NO_CREDENTIALS' : {
         return { ...state };
       }

       case 'PWA_UPDATE_PENDING_CHECKINS_COUNT' : {
         var pending_checkins_count_a = 0;
         pending_checkins_count_a     = Cache.PWA_Cache_Get_Count();
         return { ...state, pending_checkins_count: pending_checkins_count_a };
       } 

        case 'PWA_LOAD_CLASS_ATTENDEES' : {
          var temp = Attendees.PWA_Load_Class_Attendees();
          return { ...state, checkedIn: temp.attendees };
        } 
        
        case 'PWA_RESET_CLASS_ATTENDEES' : {
          Attendees.PWA_Reset_Class_Attendees();
          var temp1 = Attendees.PWA_Load_Class_Attendees();
          return { ...state, checkedIn: temp1.attendees };
        }

        
        case 'PWA_SEARCH_RESET' : {
          return { ...state, searchResult: [] };
        }
        case 'PWA_SEARCH_STUDENTS' : {
          // lets find the student/s
          const query = action.payload.toLowerCase();
          var     _result_set_1   =  state.clubStudents.filter( o =>  o.name.toLowerCase().includes(query));
          var     _result_set_2   =  state.myStudents.filter(   o =>  o.name.toLowerCase().includes(query));
          var      resultsArray   =  _result_set_1.concat( _result_set_2 );

          // lets filter out the duplicates from the concatenated array ver 1.32 1/7/2020
          // use the object.id 
          var uniq = {};
          var arrFiltered = resultsArray.filter(obj => !uniq[obj.id] && (uniq[obj.id] = true));
          // and return the filtered array not the concatednated array
          return { ...state, searchResult: arrFiltered /*resultsArray*/ };
        }
        case 'PWA_CACHE_RESET' : {
        Cache.PWA_Cache_Reset();
        return state;
        }
     
        case 'PWA_UPLOAD_CACHE_STARTED' : {
           return { ...state, show_cache_upload_spinner: true}
        }
  
        case 'PWA_UPLOAD_CACHE_SUCCESS' : {
          Cache.PWA_Cache_Reset();
          //v1.31 also update the pending checkin count //
          ////////////////////////////////////////////////
          var temp2   = Cache.PWA_Cache_Get_Count();
          return { ...state, pending_checkins_count: temp2, show_cache_upload_spinner: false };
        }

        case 'PWA_UPLOAD_CACHE_FAIL' : {
          return { ...state, show_cache_upload_spinner: false };
        }

        case 'PWA_UPLOAD_CACHE_NO_ITEMS' : {
          return { ...state, show_cache_upload_spinner: false };
        }


        case 'PWA_PROCESS_CHECKIN' : {

          ////////////////////////////////////////////
          // always add a checkin to the local cache//
          ////////////////////////////////////////////
          // v1.30 add the locationid and name
          // will be set on login/startup look in the reducer
          ///////////////////////////////////////////////////
          action.payload.locationId     = state.Location.Current.id.toString();
          action.payload.locationName   = state.Location.Current.name;
             
          Cache.PWA_Cache_Append(action.payload);

         
          ///////////////////////////////////////////
          // now need to handle the store          //
          /////////////////////////////////////////// 
          let student   = action.payload.student;
          let newArray  = state.checkedIn.slice();
          let obj       = newArray.find( o => o.student === student);
           
          let details               =  state.clubStudents.find(  o => o.checkinCode === student);
          if ( details === undefined){
               details               =  state.myStudents.find(  o => o.checkinCode === student);
          }
       
          // version 1.2.3.4 if details are still undefined
        
//         for testing multiple checkins to have the attendee list get longer
//         let _newObject = action.payload
//         _newObject["details"] = details;
//         newArray.push( _newObject );
//         add to top of array 
//         newArray.unshift( _newObject );
//         to filter the list so that duplicates are not allowed

          if (obj) {
                obj.details     = details;
                obj.timestamp   = action.payload.timestamp;
                obj.formattedts = action.payload.formattedts;
                action.payload  = obj;
          } else { 
                 let _newObject = action.payload
                 _newObject["details"] = details;
                 newArray.unshift( _newObject );
          }


          //////////////////////////////////////////////////////////////////////
          // v1.31 save the current checkedin array as list of attendees      //
          // to localstorage for reload of app or until the instructor resets //
          //////////////////////////////////////////////////////////////////////
          Attendees.PWA_Save_Class_Attendees( newArray);
          var pending_checkins_count     = Cache.PWA_Cache_Get_Count();

          return { ...state, LastCheckIn: action.payload, checkedIn: newArray, pending_checkins_count: pending_checkins_count};
     
        }

        case 'PWA_SET_VIEW': {
           return { ...state,  currentView: action.payload }
        }
        case 'PWA_SET_CURRENT_STUDENT': {
           return { ...state,  currentStudent: action.payload }
        }

        case 'PWA_SET_CURRENT_LOCATION' : {
          let oldLocation     = Object.assign({}, state.Location );
          oldLocation.Current = action.payload;
          _PWA_Set_Current_Location( oldLocation.Current);
          return { ...state, Location: oldLocation }
        }
    
        case 'PWA_LOGIN_STARTED': {
           return { ...state, showLoginSpinner: true }
        }
        case 'PWA_LOGIN_SUCCESS': {
           _PWA_Login_Success(action.payload);
           return { ...state,  showAlert: false, showAlertMessage: "", showLoginSpinner : false, token : action.payload }
        }
        case 'PWA_LOGIN_FAIL': {
           _PWA_Login_Fail();
           return { ...state,  showAlert: true, showAlertMessage: "Login Failed", showLoginSpinner : false, token: "" }
        }
        case 'PWA_LOGOFF': {
          _PWA_Logoff();
          return { ...state,   currentView: "LOGIN_VIEW", token: "", students:{}, item: {} }
        }
        case 'PWA_GET_STUDENTS_SUCCESS': {
          _PWA_Get_Students_Success(action.payload);
           return state;
        }
        case 'PWA_GET_STUDENTS_FAIL': {
           _PWA_Get_Students_Fail();
           return { ...state, students:{} }
        }
        
        case 'PWA_GET_CURRENT_TOKEN': {
          let _token = _PWA_Get_Current_Token();
          if( _token === null || _token === '') {
                return state;
          }
          return { ...state,  token: _token }
        }

        case 'PWA_GET_CURRENT_STUDENTS': {
    
        
           let _students            = _PWA_Get_Current_Students();

           if( _students === null || _students === '') { return state }
 
           let _studentsObj         = JSON.parse( _students );
           var clubStudentsArray    =  Object.values( _studentsObj.clubStudents );
           var myStudentsArray      =  _studentsObj.me.myStudents;
       
           // this is how we test to see if we should show a student
           // or an instructor login if the clubStudents array length
           // is greatet than 0, its an instructor or elevated student login
           
           let  _isInstructor       = (_studentsObj.clubStudents  && Object.keys(_studentsObj.clubStudents).length  > 0 ) ?  true  : false;
           let  _currentView        = _isInstructor ? "INSTRUCTOR_HOME_VIEW" : "STUDENT_HOME_VIEW";
        
           let  _currentInstructor  = {  "name": _studentsObj.me.name,
                                         "id"  : _studentsObj.me.id.toString() };

           let  _currentStudent     = _studentsObj.me.myStudents[0];

            ////////////////////////////////////////
            //1.30 handle the location stuff     ///
            ////////////////////////////////////////
            var tempLocation         =  {};
            tempLocation.Locations   = _studentsObj.available_locations;
            tempLocation.Required    = _studentsObj.available_locations.length > 0 ? true : false;
            tempLocation.Current     = _PWA_Get_Current_Location();

            if (tempLocation.Current.id === 0) {
                if ( _studentsObj.available_locations.length > 0 ){ 
                     _PWA_Set_Current_Location(_studentsObj.available_locations[0] )
                     tempLocation.Current = _PWA_Get_Current_Location();
                }
            }
            // end location stuff//
            //////////////////////
            
            
           return { ...state,  currentInstructor : _currentInstructor,
                               currentStudent    : _currentStudent,
                               currentView       : _currentView,
                               students          : _studentsObj,
                               clubStudents      : clubStudentsArray,
                               myStudents        : myStudentsArray,
                               Location          : tempLocation }
        }

        default:
          return state;
    }
  }


function _PWA_Login_Success( token ) {
   localStorage.setItem('PWA_Token', token );
}
function _PWA_Login_Fail() {
  localStorage.removeItem('PWA_Token');
}
function _PWA_Logoff() {
  localStorage.removeItem('PWA_Token');
  localStorage.removeItem('PWA_Students');
  localStorage.removeItem('PWA_Current_Location');
}

function _PWA_Get_Students_Success( students) {
  const _students = JSON.stringify( students );
  localStorage.setItem('PWA_Students', _students );
}
function _PWA_Get_Students_Fail() {
  localStorage.removeItem('PWA_Students');
}

function _PWA_Get_Current_Token() {
    let PWA_Token = "";
    if ( localStorage.getItem('PWA_Token')) {
        PWA_Token = localStorage.getItem('PWA_Token');
    }
    return PWA_Token;
}

function _PWA_Get_Current_Students() {
  let PWA_Students = "";
  if ( localStorage.getItem('PWA_Students')) {
      PWA_Students = localStorage.getItem('PWA_Students');
  }
  return PWA_Students;
}




/////////////////////////
// pwa_location        //
/////////////////////////
function _PWA_Set_Current_Location( obj ) {
   localStorage.setItem('PWA_Current_Location', JSON.stringify( obj ) );
}

function _PWA_Get_Current_Location() {
  if ( localStorage.getItem('PWA_Current_Location')) {
           var a1= JSON.parse( localStorage.getItem('PWA_Current_Location'));
           return a1; 
  } else {
           var a2 = JSON.parse( '{ "id":0, "name":"Location"}' );
           return a2
  }

}
