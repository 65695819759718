import React, { Component }         from 'react';
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import * as AppActions              from '../store/actions/AppActions';
import  QRCode                      from 'qrcode.react';
import { FontAwesomeIcon }          from '@fortawesome/react-fontawesome'
import { faSignOutAlt }             from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast }    from 'react-toastify';
import  InstructorHomeNav           from '../navs/InstructorHomeNav';
import enterSound                   from '../sound/enterSound.mp3';

import Card             from 'react-bootstrap/Card';
import Button           from 'react-bootstrap/Button';
import Navbar           from 'react-bootstrap/Navbar';
import Col              from 'react-bootstrap/Col';
import Badge            from 'react-bootstrap/Badge';
import Dropdown         from 'react-bootstrap/Dropdown';
import DropdownButton   from 'react-bootstrap/DropdownButton';


function mapDispatchToProps(dispatch) {
  const actions = Object.assign( {}, AppActions);
  return { actions: bindActionCreators( actions, dispatch)  };
}

function mapStateToProps(store) {
   return { AppReducer  : store.AppReducer };
}


class InstructorHomeView extends Component {

   playEnterSound      = () => {  this.audioEnter.load(); this.audioEnter.play(); }
   showToast           = ( message ) => toast.success( message, {onOpen: this.playEnterSound});
    
   constructor(props){

    super(props)
    this.state                                = {}
    this.handle_Student_Click                 = this.handle_Student_Click.bind(this);
    this.handle_Location_Click                = this.handle_Location_Click.bind(this);
    this.handle_Logout_Click                  = this.handle_Logout_Click.bind(this);
    this.handle_CheckIns_Process_View_Click   = this.handle_CheckIns_Process_View_Click.bind(this);
    this.handle_Self_CheckIn_Click            = this.handle_Self_CheckIn_Click.bind(this);
    this.setItemPrefix                        = this.setItemPrefix.bind(this);
    this.setActiveItem                        = this.setActiveItem.bind(this);
    
  }
  
  setItemPrefix( item ) {
    /////////////////////////////////////////////////////
    // set the item prefix to account Owner or student //
    /////////////////////////////////////////////////////
   
    if ( this.props.AppReducer.currentInstructor.name === item.name)
       return "Instructor";
    else
       return "student";
       
 }

 setActiveItem(item) {
  if ( this.props.AppReducer.currentStudent.checkinCode === item.checkinCode)
     return true;
  else
     return false;
     
}

   handle_Student_Click( event ) {
       let item = JSON.parse( event.currentTarget.getAttribute("data-item"))
       this.props.actions.PWA_Set_Current_Student( item );
   }

   handle_Location_Click( event ) {
      let item = JSON.parse( event.currentTarget.getAttribute("data-item"))
      this.props.actions.PWA_Set_Current_Location( item );
   }

   handle_Logout_Click ( ) {
      this.props.actions.PWA_Logoff();
   }
 
   handle_CheckIns_Process_View_Click (event) {
     this.props.actions.PWA_Set_View("CHECKINS_PROCESS_VIEW");
   }


   handle_Self_CheckIn_Click () {
      
    let  CheckInObject = { 
       instructor      :  this.props.AppReducer.currentInstructor.id,
       student         :  this.props.AppReducer.currentStudent.checkinCode,
       timestamp       :  Date.now(),
       formattedts     :  new Date().toLocaleString()
    }
  
     this.props.actions.PWA_Process_CheckIn( CheckInObject );
     let notificationTxt = "" + this.props.AppReducer.currentStudent.name  + " Checked In";
     this.showToast( notificationTxt );
     
   }
      
    
 
    render() {

       const divStyle = { marginRight: '10px'};
       let currentInstructor        = this.props.AppReducer.currentInstructor;
       let currentStudent           = this.props.AppReducer.currentStudent; 
       let renderSelect             = this.props.AppReducer.Location.Required;
    
       const myStudentsDropDownItems = this.props.AppReducer.students.me.myStudents.map( (item, key) =>
       <Dropdown.Item  active            = { this.setActiveItem(item) }
                       onClick           = { this.handle_Student_Click }
                       key               = { key}
                       data-name         = { item.name }
                       data-id           = { item.id   }
                       data-checkincode  = { item.checkinCode }
                       data-item         = { JSON.stringify(item) }
                       eventKey          = { item.checkinCode}>
                       <Badge className="mr-2" variant="primary">
                          { this.setItemPrefix( item ) }
                       </Badge>{item.name}
                       </Dropdown.Item>
      )

      const myLocations = this.props.AppReducer.Location.Locations.map( (item, key) =>
              <Dropdown.Item  onClick   = { this.handle_Location_Click }
                              key       = { key}
                              data-item = { JSON.stringify(item) }
                              eventKey  = { key }> {item.name}</Dropdown.Item>
       )


       return (
      
         <div>
             
            <ToastContainer
               position="top-right"
               autoClose={2000}
               hideProgressBar={false}
               newestOnTop
               closeOnClick={false}
               rtl={false}
               pauseOnVisibilityChange
               draggable={false}
               pauseOnHover={false}
            />


         <audio ref={r => this.audioEnter = r}>
           <source
            src={enterSound}
            type="audio/mpeg"
           />
         </audio>


          <Card className="mt-2">
            
            <Card.Header>
                <h6 className="text-center"> Account Manager: { currentInstructor.name}</h6> 

                {  renderSelect ?
                  <div className="flex-container">                   
                     <div style={divStyle}>Location:   </div>
                     <DropdownButton  id        = "dropdown-basic-button"
                                      title     = { this.props.AppReducer.Location.Current.name}>
                                      {myLocations}
                     </DropdownButton>
                 </div>
                : null
               }

            </Card.Header>
            
            <Card.Body >

               <div className="dropdown_flex-container">   
                      <DropdownButton  size      ="lg"
                                       title     = { this.props.AppReducer.currentStudent.name}>
                                      {myStudentsDropDownItems}
                     </DropdownButton>
               </div>
           
                {/* <ListGroup className="listgroupscroll" >{myStudents}</ListGroup>  */}
           
            </Card.Body>

            <Card.Footer className="text-center">
              
                <Col xs="12">
                   <p>{ currentStudent.name }</p>
                   <QRCode size = {160} value = { currentStudent.checkinCode} />
                   <p className="mt-2">{ currentStudent.checkinCode }</p>
                </Col>
 
                <Col xs="12">
                 <Button onClick={ this.handle_Self_CheckIn_Click } variant="outline-success" >
                    <FontAwesomeIcon className="mr-1" icon={faSignOutAlt} />Manual Checkin 
                 </Button>
               </Col>

           
            </Card.Footer>

          </Card>


          <Navbar fixed="bottom" bg="light" variant="light">
              <InstructorHomeNav actions = { this.props.actions }></InstructorHomeNav> 
          </Navbar>
    
          </div>
   
      );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)( InstructorHomeView );

/* used to be the students list view, changed to a dropdown in 1.31
  const myStudents = this.props.AppReducer.students.me.myStudents.map( (item, key) =>
        <ListGroup.Item className="py-2"
                        action
                        active            = { this.setActiveItem(item) }
                        onClick           = { this.handle_Student_Click }
                        key               = { key}
                        data-name         = { item.name }
                        data-id           = { item.id   }
                        data-checkincode  = { item.checkinCode }
                        data-item         = { JSON.stringify(item) }
                        eventKey          = { item.checkinCode}>
                           <Badge className="mr-2" variant="primary">
                           { this.setItemPrefix( item ) }</Badge>{item.name}
                        </ListGroup.Item>
       )
*/