import React from 'react';
import  Button                       from 'react-bootstrap/Button';
import * as uploader                 from "./uploader";
import * as Cache                    from "../store/cache"
import * as Credentials              from "../store/credentials"
import { connect }                   from 'react-redux';
import { bindActionCreators }        from 'redux';
import * as AppActions               from '../store/actions/AppActions';

import Modal      from 'react-bootstrap/Modal';

function mapDispatchToProps(dispatch) {
   const actions = Object.assign( {}, AppActions );
   return { actions: bindActionCreators( actions, dispatch)  };
 }
 
 function mapStateToProps(store) {
   return {  AppReducer  : store.AppReducer };
 }
 

// obj contains the store and the actions
class CloseClassButton extends React.Component {

   constructor(props) {
      
      super(props);

      this.preStudentsArray  = [];
      this.postStudentsArray = [];
      
      this.state = {
         'students'         : [],
         'localcache'       : [],
         'uploadresult'     : {},
         'tokenresult'      : {},
         'showUploadDialog' : false, 
         'log'              : [],
         'verbose'          : false,
       }

        this.CloseClassClick = this.CloseClassClick.bind(this);
     }
        
   
      appendLog = ( message ) => {
          var event          = {}
          var dateTime       = new Date().toLocaleString(); 
          event.timestamp    = dateTime;
          event.message      = message;
          var newStateArray  = this.state.log.slice();
          newStateArray.push( event );
          this.setState( { log : newStateArray} );
      };

      setPreStudentArray = ( cache ) => {
     
         const obj          = JSON.parse( cache);
         const  preStudents = obj.checkins.map(  (item ) => {
             return (item.student);
         })

         this.preStudentsArray = preStudents;
   
      };

      setPostStudentArray = ( postStudents ) => {
           this.postStudentsArray = postStudents;
      };
    
      verifyCounts    = () => {
     
         var   result = "";

         const preCount  = this.preStudentsArray.length;
         const postCount = this.postStudentsArray.length;
           
         if ( preCount !== postCount){
              result = `pre/post count match : failed  ${preCount}/${postCount}`
         } else {
              result = `pre/post count match : success ${preCount}/${postCount}`
         }
      
         return result;
      
      }

      verifyArrays     = () => {
       
            var missingstudents = "";

            this.preStudentsArray.map ( (item) => {
                var index = this.postStudentsArray.indexOf( item );
                  if ( index  === -1 ) { missingstudents += item + "," }
                return item;
            });

            if ( missingstudents.length === 0 )
                 return "pre/post students match : success";
            else
                 return `pre/post students match : failed - ${missingstudents}`;
      };

      closeUploadDialog = () => {
         this.setState( { log : [], showUploadDialog : false });
      };
  
      startUpload = async (event) => {

         var tokenResult         = {};
         var cacheResult         = {};
  
         this.appendLog("Cache upload : Started");

              
         const credentials = Credentials.PWA_Load_Credentials();
         if ( credentials === null) {
            this.appendLog("No credentials provided" );
            this.appendLog("Cache upload : ended"  );
            return;
         }
                  
         const cachesize = Cache.PWA_Cache_Get_Size()
         if ( cachesize === 0 ) {
              this.appendLog("The cache size is 0 nothing to do" );
              this.appendLog("Cache upload : Ended" );
              return;
         }
          
         const cachecount  = Cache.PWA_Cache_Get_Count();
         if ( cachecount   === 0 ){
              this.appendLog("The cache count is 0 nothing to do" );
              this.appendLog("Cache upload : Ended" );
              return;
         }
  
         this.appendLog(`Cache size  is ${cachesize}`);
         this.appendLog(`Cache count is ${cachecount}`);
         
         // get the currentCache
         try {
             // get the cached checkins
             cacheResult.cache   = localStorage.getItem('PWA_Cache'); 
             cacheResult.status  = 200;
             const temp          = JSON.parse(cacheResult.cache);
             cacheResult.count   = temp.checkins.length;
             if ( this.state.verbose)
                  this.appendLog("Get Current Cache Success");
         
          } catch (error) {
                this.appendLog("Get Current Cache Failed");
                this.appendLog(error.message);
                this.appendLog("Cache Upload : Ended");
                return;
         }
  
         /////////////////////
         // get a new token //
         /////////////////////
         try{
             tokenResult = await uploader.get_token( credentials );
             if ( this.state.verbose)
                  this.appendLog("Token received success");
          } catch(error) {
               this.appendLog("Token received failed");
               this.appendLog( error.message);
               this.appendLog("Cache Upload : Ended");
               return;
         };


  
         // upload the current cache
         try{
           // upload the current cache
           this.appendLog("------------------------");
           this.appendLog("Metrics before upload : ");
           this.appendLog(`Cache count  : ${cacheResult.count}`);
      
           if ( this.state.verbose)
                this.appendLog(`Cache size   : ${cachesize}`);

            this.setPreStudentArray( cacheResult.cache );
           
            const result = await uploader.upload_cache( tokenResult, cacheResult );
   
           this.appendLog("-----------------------");
           this.appendLog("Metrics after upload : ");
      
           if ( this.state.verbose)
                this.appendLog("server status      : "  + result.status );
   
         
            this.appendLog("Server count       : "  + result.data.studentCount   );
            this.setPostStudentArray( result.data.students );
           
           this.appendLog("-----------------------");
           if ( this.state.verbose)
              this.appendLog("laravel  response    : "  + result.data.success        );

           const verifyCounts  = this.verifyCounts();
           this.appendLog(verifyCounts);
         
           const verifyArrays  = this.verifyArrays();
           this.appendLog(verifyArrays);
           

        } catch(error) {
           this.appendLog(`Cache upload failed  - ${error.message}`);
           this.appendLog("Cache upload : Ended");
           return;
        };
  
        this.appendLog("Cache upload : Ended");
     
        this.props.actions.PWA_Reset_Class_Attendees();
        this.props.actions.PWA_Close_Class_Success();

        return;

      };

      CloseClassClick() {

        const networkConfirmation      = "this action will\nReset the class\nclear the attendees list\nUpload and clear the current cache\nAre you sure?";
        const noNetworkConfirmation    = "** No Network is Available !!!**\nThis action will\nReset this class and clear the class attendees\nAre you sure?";

         let response  = false;
         let isOnline  = false;
         isOnline      = navigator.onLine;
     
         if ( isOnline ) { 
             // true = network
             response  =  window.confirm( networkConfirmation );
         } else {
             response  =  window.confirm( noNetworkConfirmation );
         }  
 
         if ( response === false) {      // negative response
               return;
         }
     
         if ( response && isOnline ) {
            this.setState( { log : [], showUploadDialog : true }); 
            setTimeout(  function() {  this.startUpload() }.bind(this), 50);
         }

    };



    render() {

    
      return (
        <div>
            <Modal   show    =  { this.state.showUploadDialog } 
                     onHide  =  { this.closeUploadDialog }
            >
             
            <Modal.Body>
               <React.Fragment>
                    { this.state.log.map( (item,index) => (
                        <h6 key={index}> {item.timestamp} : {item.message} </h6>
                     ))}
              </React.Fragment>
            </Modal.Body>
             
             <Modal.Footer>
                 <Button variant="primary" onClick={this.closeUploadDialog}>Close</Button>

             </Modal.Footer>
      
            </Modal>


            <Modal   show    =  { this.state.showNoNetwork } onHide  =  { this.closeNoNetwork } >
                          
            <Modal.Body>
            </Modal.Body>
             
             <Modal.Footer>
                 <Button variant="primary" onClick={this.closeNoNetwork}>Close</Button>
             </Modal.Footer>
            </Modal>



            <Button  onClick = { this.CloseClassClick } variant  ="success">Close Class</Button>
        </div>
      );
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseClassButton);