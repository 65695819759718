import React, { Component }         from 'react';
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';

import * as AppActions             from './store/actions/AppActions';
import Container                   from 'react-bootstrap/Container';

import CheckInsProcessView       from './views/CheckInsProcessView';
import CheckInsCurrentView       from './views/CheckInsCurrentView';

import LoginView                  from './views/LoginView';

import InstructorHomeView         from './views/InstructorHomeView';

import StudentHomeView           from './views/StudentHomeView';

import SearchView                from './views/SearchView';

import 'react-toastify/dist/ReactToastify.min.css';
import './App.css';


function mapDispatchToProps(dispatch) {
  const actions = Object.assign( {}, AppActions );
  return { actions: bindActionCreators( actions, dispatch)  };
}

function mapStateToProps(store) {
  return { AppReducer  : store.AppReducer };
}

class App extends Component {

  componentDidMount() {
    
    this.props.actions.PWA_Get_Current_Token();
    this.props.actions.PWA_Get_Current_Students();
    this.props.actions.PWA_update_Pending_Checkins_Count();
    
    // 1.3.3.2 no longer automatic
    // manual upload of cache is required
    // setInterval(  function() {  this.props.actions.PWA_UpLoadCache(); }.bind(this), 60*1000);
  }
  
  render() {
    
    let showInstructorHomeView      =  ( this.props.AppReducer.currentView  === "INSTRUCTOR_HOME_VIEW"    ) ? true : false;
    let showStudentHomeView         =  ( this.props.AppReducer.currentView  === "STUDENT_HOME_VIEW"       ) ? true : false;
    let showLoginView               =  ( this.props.AppReducer.currentView  === "LOGIN_VIEW"    ) ? true : false;
    let showCheckInsProcessView     =  ( this.props.AppReducer.currentView  === "CHECKINS_PROCESS_VIEW" ) ? true : false;
    let showCheckInsCurrentView     =  ( this.props.AppReducer.currentView  === "CHECKINS_CURRENT_VIEW" ) ? true : false
    let showSearchView              =  ( this.props.AppReducer.currentView  === "SEARCH_VIEW" ) ? true : false
    
    return (

      <Container>
        { showInstructorHomeView    ? ( <InstructorHomeView           /> ) : ""}
        { showStudentHomeView       ? ( <StudentHomeView        /> ) : ""}
        { showLoginView             ? ( <LoginView              /> ) : ""}
        { showCheckInsProcessView   ? ( <CheckInsProcessView    /> ) : ""}
        { showCheckInsCurrentView   ? ( <CheckInsCurrentView    /> ) : ""}
        { showSearchView            ? ( <SearchView             /> ) : ""}
        
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)( App );