import React, { Component }         from 'react';
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import * as AppActions              from '../store/actions/AppActions';
import  QRCode                      from 'qrcode.react';
import  StudentHomeNav              from '../navs/StudentHomeNav';

import Card      from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Navbar    from 'react-bootstrap/Navbar';

function mapDispatchToProps(dispatch) {
  const actions = Object.assign( {}, AppActions);
  return { actions: bindActionCreators( actions, dispatch)  };
}

function mapStateToProps(store) {
   return { AppReducer  : store.AppReducer };
}

class StudentHomeView extends Component {

  constructor(props){
    super(props)
    this.handleStudentClick  = this.handleStudentClick.bind(this);
    this.handleLogoutClick   = this.handleLogoutClick.bind(this);
    this.setActiveItem       = this.setActiveItem.bind(this);
  }
 
   handleStudentClick(event) {

      let item = JSON.parse( event.currentTarget.getAttribute("data-item"))
      this.props.actions.PWA_Set_Current_Student( item );
   
    }
  
   setActiveItem(item, key) {
      console.log("setActiveItem");
      if ( this.props.AppReducer.currentStudent.checkinCode === item.checkinCode)
         return true;
      else
         return false;
         
   }
  
  handleLogoutClick (event) {
     this.props.actions.PWA_Logoff();
  }
 
 
    render() {

       let currentStudent = this.props.AppReducer.currentStudent;

       const myStudents = this.props.AppReducer.students.me.myStudents.map( (item, key) =>
        <ListGroup.Item className="text-center"
                        action
                        active           = { this.setActiveItem(item) }
                        onClick          = { this.handleStudentClick}
                        key              = { key }
                        data-name        = { item.name }
                        data-id          = { item.id   }
                        data-checkincode = { item.checkinCode }
                        data-item        = { JSON.stringify(item) }
                        eventKey         = { item.checkinCode}>{item.name}</ListGroup.Item>
       )

       return (
      
         <div>
             <Card className="mt-2">

            <Card.Header>
               <h5 className = "text-center">{ currentStudent.name}</h5> 
            </Card.Header>
            
            <Card.Body className="text-center">
                <ListGroup>{myStudents}</ListGroup> 
            </Card.Body>

            <Card.Footer className="text-center">
               <QRCode className="mt-1" value = { currentStudent.checkinCode} size = {200} />
                <p>{ currentStudent.checkinCode}</p>
            </Card.Footer>
            </Card>
             <Navbar fixed="bottom" bg="light" variant="light">
              <StudentHomeNav actions = { this.props.actions }></StudentHomeNav> 
            </Navbar>
          </div>
   
      );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)( StudentHomeView );