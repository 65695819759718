import React, { Component }         from 'react';
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import * as AppActions              from '../store/actions/AppActions';

import Card           from 'react-bootstrap/Card';
import Spinner        from 'react-bootstrap/Spinner';
import Button         from 'react-bootstrap/Button';
import Alert          from 'react-bootstrap/Alert';
import Table          from 'react-bootstrap/Table';
import Modal          from 'react-bootstrap/Modal';
import Container      from 'react-bootstrap/Container';

import LoginForm      from '../components/LoginForm';


import { FontAwesomeIcon }          from '@fortawesome/react-fontawesome'
import { faInfoCircle    }          from "@fortawesome/free-solid-svg-icons";
import { faCopyright     }          from "@fortawesome/free-solid-svg-icons";


function mapDispatchToProps(dispatch) {
  const actions = Object.assign( {}, AppActions );
  return { actions: bindActionCreators( actions, dispatch)  };
}

function mapStateToProps(store) {
  return {  AppReducer     : store.AppReducer };
}

function ShowSpinner(props) {
    const _showSpinner = props.showSpinner;
    if ( _showSpinner ) {
         return <Button className="mr-2" size="sm" variant="primary" disabled>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                </Button>;
    } else
        return null;
}

function ShowAlert(props) {
  const _showAlert        = props.showAlert;
  const _showAlertMessage = props.showAlertMessage;
  if ( _showAlert ) {
        return <Alert variant="danger">{_showAlertMessage}</Alert>;
  } else
      return null;
}

class LoginView extends Component {
 
  constructor(props){
     super( props )
     this.state = {
        showAbout   : false,
        version     : "1.46",
        name        : "Attendance PWA",
        copyright   : "ClubScope",
        title       : "User Login",
        // added in 1.33
        year        : new Date().getFullYear()
     }
  
     this.handle_Close_About   = this.handle_Close_About.bind(this);
     this.handle_Show_About    = this.handle_Show_About.bind(this);
  
  }

  handle_Close_About() {
    this.setState( {showAbout : false});
  }
 
  handle_Show_About() {
    this.setState( {showAbout : true});
  }

   render() {
  
     return (

      <div>
 
       <Modal show={this.state.showAbout} onHide={this.handle_Close_About}>
          
             <Modal.Header  className="emergencyinfoAlignHeader" closeButton >
                    <span>About {this.state.name}</span>
             </Modal.Header>
             
             <Modal.Body>
              <Table bordered hover>
                <tbody>
                  <tr>
                    <td>PWA Version :</td>
                    <td>{this.state.version}</td>
                  </tr>
                  <tr>
                    <td>PWA Help :</td>
                    <td><a href="https://www.clubscope.co.uk/pwa-guide/">View Guide!</a></td>
                  </tr>
                  <tr>
                    <td>Copyright :</td>
                    <td><FontAwesomeIcon icon={faCopyright}/>&nbsp; 2019 - {this.state.year} , {this.state.copyright}</td>
                  </tr>
                </tbody>
              </Table>
             </Modal.Body>
             
             <Modal.Footer>
                 <Button variant="primary" onClick={this.handle_Close_About}>Close</Button>
             </Modal.Footer>

       </Modal>
    
       <Card className="mt-2">
         
           <Card.Header className="loginView_Header">
            <Container>
              <div className="d-flex flex-row justify-content-between">
             
              <Button variant="primary" onClick={ this.handle_Show_About }>
                <div className="d-flex flex-row">
                  <div><FontAwesomeIcon icon={faInfoCircle} /></div>
                  <div>&nbsp;&nbsp;About</div>
                </div>
              </Button>

              <span className="ml-1 mr-3">{this.state.title}</span>
          
              <ShowSpinner showSpinner={this.props.AppReducer.showLoginSpinner }></ShowSpinner>
              
              </div>
            </Container>
           </Card.Header>
          
          <Card.Body>
              <LoginForm />
          </Card.Body>

          <Card.Footer>
              <ShowAlert showAlertMessage = {this.props.AppReducer.showAlertMessage }
                         showAlert        = {this.props.AppReducer.showAlert }>
              </ShowAlert>
          </Card.Footer>
    
       </Card>
      
      </div>
 
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)( LoginView );