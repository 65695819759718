import React from 'react';
import { makeStyles }              from '@material-ui/core/styles';
import BottomNavigation            from '@material-ui/core/BottomNavigation';
import BottomNavigationAction      from '@material-ui/core/BottomNavigationAction';
import { FontAwesomeIcon }         from '@fortawesome/react-fontawesome'
import { faHome}                   from "@fortawesome/free-solid-svg-icons";
import { faCheckDouble}            from "@fortawesome/free-solid-svg-icons";
import { faBook       }            from "@fortawesome/free-solid-svg-icons";


const useStyles = makeStyles({
  root: {
    width: 500,
  },
});

function SearchNav( actions ) {
  const classes           = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
       value={value}
       
       onChange={ (event, newValue) => { 
            setValue(newValue);
            switch(newValue) {
              case 0:
                  actions.actions.PWA_Set_View("INSTRUCTOR_HOME_VIEW");
                  break;
              case 1:
                  actions.actions.PWA_Set_View("CHECKINS_PROCESS_VIEW");
                  break;
              case 2:
                  actions.actions.PWA_Set_View("CHECKINS_CURRENT_VIEW");
                  break;
              default:
            }
          }
       }
       
       showLabels
       className={classes.root}
    >
     <BottomNavigationAction label="Home"         icon={<FontAwesomeIcon icon={faHome}/>} />
     <BottomNavigationAction label="Check In"     icon={<FontAwesomeIcon icon={faCheckDouble}/>} />
     <BottomNavigationAction label="Attendees"    icon={<FontAwesomeIcon icon={faBook}/>} />
    </BottomNavigation>
  );
}

export default SearchNav