import React, { Component }         from 'react';
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import * as AppActions              from '../store/actions/AppActions';
import { FontAwesomeIcon }          from '@fortawesome/react-fontawesome'

import { faUserCheck}               from "@fortawesome/free-solid-svg-icons";
import { faUserSlash}               from "@fortawesome/free-solid-svg-icons";
import { faNotesMedical}            from "@fortawesome/free-solid-svg-icons";


import  AttendeesNav                from '../navs/AttendeesNav';

import Card       from 'react-bootstrap/Card';
import ListGroup  from 'react-bootstrap/ListGroup';
import Modal      from 'react-bootstrap/Modal';
import Button     from 'react-bootstrap/Button';
import Navbar     from 'react-bootstrap/Navbar';
import Badge      from 'react-bootstrap/Badge';

import CloseClassButton            from '../components/CloseClassButton';
import * as utilityMethods         from '../components/UtilityClass';



function mapDispatchToProps(dispatch) {
   const actions = Object.assign( {}, AppActions );
   return { actions: bindActionCreators( actions, dispatch)  };
}

function mapStateToProps(store) {
  return { AppReducer : store.AppReducer };
}

class CheckInsCurrentView extends Component {
  
 constructor(props){
     super(props)
     this.state                  = { showDetails : false, itemDetails: {} }
     this.handle_Item_Click      = this.handle_Item_Click.bind(this);
     this.handle_Close_Details   = this.handle_Close_Details.bind(this);
     this.handle_Close_Class     = this.handle_Close_Class.bind(this);
 }
 
   handle_Close_Details() {
     this.setState( {showDetails : false, itemDetails: {}} )
   }

   handle_Item_Click(event) {
      let item = JSON.parse( event.currentTarget.getAttribute("data-item"))
      this.setState( {showDetails : true, itemDetails: item.details })
   }
   
    // will reset the class attendees
    handle_Close_Class(event) {
      // need a confirmation here
     if (window.confirm('this action will reset the class and clear the attendees, Are you sure?')){
         this.props.actions.PWA_Reset_Class_Attendees();
         this.props.actions.PWA_Reset_Class_Attendees();
     }
  }
 

 componentDidMount() {
     console.log("CheckinCurrentView Mounted");
     this.props.actions.PWA_Load_Class_Attendees();
   }

  render() {

     // 1.3.3 add head count/checkin count to attendees list header
     const checkedInCount  = this.props.AppReducer.checkedIn ? this.props.AppReducer.checkedIn.length : 0;
     const checkedIn       = this.props.AppReducer.checkedIn.map( (item, key) =>
         <ListGroup.Item as="li" className="text-center"
          action
          onClick           = { item.details ? this.handle_Item_Click : "" }
          key               = { key }
          data-item         = { JSON.stringify(item) }
          eventKey          = { key }>

         {item.details && item.details.medicalNotes ?
            <FontAwesomeIcon  className="mr-2" color="red" icon={faNotesMedical}/>
            : null
         }  
          
         {item.details ? 
            <FontAwesomeIcon className="mr-2" icon={faUserCheck}/>
          : <FontAwesomeIcon className="mr-2" icon={faUserSlash}/>
         }
          
          {item.details ? item.details.name  : item.student}  
        
        </ListGroup.Item>
     )
   
     const ageInYears                    =   ( typeof this.state.itemDetails.dateOfBirth     !== 'undefined'  ) ?  utilityMethods.ageInYears(this.state.itemDetails.dateOfBirth)           : 'N/A';     
     const lastGradingDate               =   ( typeof this.state.itemDetails.lastGradingDate !== 'undefined'  ) ?  utilityMethods.lastGradingDate(this.state.itemDetails.lastGradingDate)  : 'N/A';     
     const presentGrade                  =   ( typeof this.state.itemDetails.presentGrade    !== 'undefined'  ) ?  utilityMethods.presentGrade(this.state.itemDetails.presentGrade)        : 'N/A';


     return (
      
      <Card className="mt-2 text-center">
       
          <Modal  show={this.state.showDetails} onHide={this.handle_Close_Details}>
             <Modal.Header  className="emergencyinfoAlignHeader" closeButton >
               {this.state.itemDetails && this.state.itemDetails.medicalNotes ? <FontAwesomeIcon  className="mr-2" color="red" icon={faNotesMedical}/> : null }  
               {this.state.itemDetails.name}
             </Modal.Header>
             
             <Modal.Body>

               
               {/* added in 1.32 1/7/2020 attendanceCount */}
               <span className="mb-1">Lessons Required to Grade : &nbsp;
                 { this.state.itemDetails.hasOwnProperty("attendanceCount") ? this.state.itemDetails.attendanceCount : "NA" }
               </span> 
               <br/><br/>

               <span className="mb-1">Emergency Contact:<br/>

               Name             : {this.state.itemDetails.emergencyContactName}<br/>
               
               Relation         : {this.state.itemDetails.emergencyContactRelationship}<br/>
               Telephone        : {this.state.itemDetails.emergencyContactTelephone}<br/>

               {/* added in 1.42 5/28/2024 Age */}
               Age                 : { ageInYears      }<br/>    
               Present Grade       : { presentGrade    }<br/>
               Last Graded         : { lastGradingDate }<br/>


               </span>
               <br/>

               {/* added in 1.32 1/7/2020 medicalNotes */}
               <span className="mb-1">Medical Notes:<br/>
                 {this.state.itemDetails.medicalNotes  ? this.state.itemDetails.medicalNotes  : "None" }
               </span>

             </Modal.Body>
             
             <Modal.Footer>
                <Button variant="primary" onClick={this.handle_Close_Details}>Close</Button>
             </Modal.Footer>
          </Modal>


          <Card.Header>
             <div id="attendees_card_header">
              <h6>Class Attendees : <Badge variant="info">{checkedInCount}</Badge></h6>
              {/*  <Button variant="primary" onClick={this.handle_Close_Class}>Close Class</Button>  */}
              {/*1.3.3.2  closeClassButton is a component*/}
              <CloseClassButton  actions = { this.props.actions }/>
              </div>
          </Card.Header>

           <ListGroup as="ul" className="attendees_listgroup">{checkedIn}</ListGroup> 
             
          <Navbar fixed="bottom" bg="light" variant="light">
               <AttendeesNav actions = { this.props.actions }></AttendeesNav> 
          </Navbar>
      
       </Card>


  );

  }
}

export default connect(mapStateToProps, mapDispatchToProps)( CheckInsCurrentView );